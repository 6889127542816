import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/styles/main.scss'
import './axios'
import VueBrowserGeolocation from 'vue-browser-geolocation'
import VueEllipseProgress from 'vue-ellipse-progress'
import VueBingMaps from 'vue-bing-maps'


Vue.config.productionTip = false

// eslint-disable-next-line no-console
Vue.use(VueBingMaps);
window.Vue = Vue;

Vue.use(VueBrowserGeolocation);
Vue.use(VueEllipseProgress);

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import './registerServiceWorker'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBZ81_lXspsjgcnZrn2_jE3HCyv1GMz-ps",
  authDomain: "simpres-mobile.firebaseapp.com",
  projectId: "simpres-mobile",
  storageBucket: "simpres-mobile.appspot.com",
  messagingSenderId: "606891736926",
  appId: "1:606891736926:web:aa1686e00b0a9f3dcc99cf",
  measurementId: "G-5N3FCC3BQW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service worker berhasil didaftarkan:', registration);
      })
      .catch((error) => {
        console.error('Gagal mendaftarkan service worker:', error);
      });
  });
}

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    onMessage(messaging, (payload) => {
      this.notif(payload.data.body, 
        payload.data.title, 
        "info")
    });
  },
  methods: {
    notif(msg, title, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: "b-toaster-top-full",
        
      });
    },
  },
}).$mount('#app')
