var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"baik-app d-flex mx-auto flex-column"},[(
      _vm.$route.name != 'Login' &&
      _vm.$route.name != 'Form Absen Security' &&
      _vm.$route.name != 'Absen Security'
    )?_c('TitleBar',{attrs:{"title":_vm.$route.name}}):_vm._e(),_c('router-view'),(
      _vm.$route.name != 'Login' &&
      _vm.$route.name != 'Home' &&
      _vm.$route.name != 'Absen Security' &&
      _vm.$route.name != 'Form Absen Security'
    )?_c('MenuBar'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }