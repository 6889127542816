<template>
  <div class="baik-app d-flex mx-auto flex-column">
    <TitleBar
      v-if="
        $route.name != 'Login' &&
        $route.name != 'Form Absen Security' &&
        $route.name != 'Absen Security'
      "
      :title="$route.name"
    />
    <router-view />
    <MenuBar
      v-if="
        $route.name != 'Login' &&
        $route.name != 'Home' &&
        $route.name != 'Absen Security' &&
        $route.name != 'Form Absen Security'
      "
    />
  </div>
</template>
<script>
import TitleBar from "./components/TitleBar.vue";
import MenuBar from "./components/MenuBar.vue";
export default {
  components: {
    TitleBar,
    MenuBar,
  },
};
</script>

