<template>
  <div class="baik-title w-100 bg-title">
    <b-row class="g-0 align-items-center" no-gutters>
      <b-col class="bt-left d-flex justify-content-start p-3">
        <h6 class="m-0 p-0 text-light">{{ title }}</h6>
      </b-col>
      <b-col class="bt-right d-flex justify-content-end pr-3">
        <router-link to="/profile" class="text-light">
          <b-icon icon="gear" />
        </router-link>
      </b-col>
    </b-row>
  </div>
</template>
<style>
.bg-title {
  text-transform: uppercase;
  border: none !important;
  width: 100%;
  background: linear-gradient(
    320deg,
    rgba(54, 97, 96, 1) 3%,
    rgb(60, 187, 206) 38%,
    rgb(47, 177, 168) 68%,
    rgb(19, 123, 121) 98%
  );
  animation: gradient 15s ease infinite;
  background-size: 300% 300%;
  background-attachment: fixed;
  @keyframes gradient {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
}
</style>
<script>
export default {
  props: {
    title: String,
  },
};
</script>