export default (to, from, next) => {
  if (localStorage.getItem('baikKaryawan') != null && localStorage.getItem('baikKaryawan').length > 0) {
    next()
    console.log('masuk')
  } else {
    localStorage.removeItem('baikKaryawan')
    console.log('tidak masuk')
    next('/login')
  }
}
